<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div style="width: 100%">
          <v-row no-gutters class="pr-2" justify="space-between" align="center">
            <div class="d-inline-flex align-center">
              <v-switch v-model="activeOnly" :label="$lang.labels.activeOnly" class="px-3 pt-2"></v-switch>
              <v-text-field
                v-model="search"
                outlined
                clearable
                hide-details
                dense
                :label="$lang.labels.searchByName"
                data-cy="search-by-name"
              ></v-text-field>
              <v-autocomplete
                v-model="processId"
                outlined
                dense
                :items="processes"
                :loading="isLoadingProcesses"
                :search-input.sync="searchProcesses"
                hide-no-data
                hide-details
                clearable
                item-text="name"
                item-value="id"
                :label="$lang.labels.process"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                class="pl-2"
              ></v-autocomplete>
              <div class="pb-2">
                <v-btn
                  text
                  color="primary"
                  class="ml-2 mt-1"
                  @click="$emit('fetchRests', { options: options, search: search && search.length > 1 ? search : '', processId: processId, url, method, resetPage: true })"
                ><v-icon>mdi-refresh</v-icon></v-btn>
              </div>
            </div>
            <v-btn v-if="roles.includes('TRIGGER_CREATOR')" color="accent" class="color-accent-text" :to="{ name: 'restCreate', params: { lang: $lang.current_lang } }">{{ $lang.actions.create }}</v-btn>
          </v-row>
          <v-row no-gutters class="px-2 mb-2" align="center">
            <v-text-field
              v-model="url"
              outlined
              clearable
              hide-details
              class="mt-2"
              style="max-width: 385px"
              dense
              :label="$lang.labels.url"
              data-cy="search-by-url"
            />
            <v-select
              v-model="method"
              :items="methods"
              :label="$lang.labels.method"
              outlined
              hide-details
              dense
              clearable
              data-cy="search-by-method"
              class="ml-md-2 mt-2"
              style="max-width: 255px"
            />
          </v-row>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.method="{ item }">
        <div>{{ $lang.status[item.method] }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>

      <template v-slot:item.comment="{ item }">
        <div style="white-space: nowrap;overflow: hidden;max-width: 300px;text-overflow: ellipsis">{{ item.comment }}</div>
      </template>

      <template v-slot:item.basicAccessAuthentication="{ item }">
        <div>{{ item.basicAccessAuthentication ? $lang.status.YES : $lang.status.NO }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="secondary" class="mx-1 button-default-width" @click="$emit('changeStatus', {id: item.id, status: item.status})">{{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}</v-btn>
          <v-btn color="primary" class="mx-0 button-default-width" :to="{ name: 'restEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
          <v-btn
            v-if="roles.includes('TRIGGER_CREATOR')"
            color="accent"
            class="ml-1 button-default-width color-accent-text"
            @click="copyCreateResource(item, 'restCreate', $router, '', $options.filters.formatDateTime(new Date()), false, true)"
          >{{ $lang.actions.copyCreate }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { getProcessByIdUsingGET as getProcess, getProcessesUsingGET as getProcesses } from '@/utils/api'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      url: '',
      method: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.method, value: 'method' },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.basicAccessAuthentication, value: 'basicAccessAuthentication', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      isLoadingProcesses: false,
      processId: '',
      processes: [],
      searchProcesses: '',
      methods: ['GET', 'POST', 'PUT', 'DELETE']
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method })
      },
      deep: true
    },
    search: {
      handler (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchRests', { options: this.options, search: val, processId: this.processId, url: this.url, method: this.method, resetPage: true })
        if (!this.lock && val === null || val === '') this.$emit('fetchRests', { options: this.options, search: '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
        this.savePreFill()
      }
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true } })
        this.savePreFill()
      }
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    },
    processId: {
      handler() {
        if (!this.lock) this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
        this.savePreFill()
      }
    },
    url: {
      handler() {
        if (!this.lock) this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
        this.savePreFill()
      }
    },
    method: {
      handler() {
        if (!this.lock) this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
        this.savePreFill()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(3, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    // eslint-disable-next-line consistent-return
    this.headers.forEach((item, index) => {
      if (item.value === 'actions') {
        if (this.userSettings.display.showComments) {
          this.headers.splice(index, 0, { text: this.$lang.labels.comment, value: 'comment' })
        }
      }
    })

    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      if (this.$route.query && this.$route.query.processId) {
        this.processId = this.$route.query.processId
      }
      if (this.$route.query && this.$route.query.search) {
        this.search = this.$route.query.search
      }
      if (this.$route.query.activeOnly) {
        this.activeOnly = this.$route.query.activeOnly === 'true'
        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method } })
      } else {
        this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
      }
    } else if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.restList) {
        this.search = preFill.restList.search
        this.activeOnly = preFill.restList.activeOnly
        this.processId = preFill.restList.processId
        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true } })
      } else {
        this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
      }
    } else {
      this.$emit('fetchRests', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, url: this.url, method: this.method, resetPage: true })
    }
    if (this.processId) {
      this.loadPreFill()
    } else {
      setTimeout(() => this.lock = false, 100)
    }
  },
  methods: {
    copyCreateResource,
    loadPreFill() {
      this.isLoadingProcesses = true
      getProcess({
        id : this.processId
      })
        .then((res) => {
          this.processId = ''
          this.processes = [res.data.data]
          this.isLoadingProcesses = false
          this.searchProcesses = res.data.data.name
          this.processId = res.data.data.id
          setTimeout(() => {
            this.lock = false
          }, 50)
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.restList) preFill.restList = {
          search: '',
          activeOnly: true,
          processId: ''
        }

        preFill.restList.search = this.search
        preFill.restList.activeOnly = this.activeOnly
        preFill.restList.processId = this.processId

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
